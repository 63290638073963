import useData from "hooks/useData";
import { useContext, useEffect, useRef, useState } from "react";
import { FiSettings, FiX, FiXSquare } from "react-icons/fi";
import WebGLContext from "./WebGlContext";
import Area from "components/charts/Area/Area";
import classNames from "utilities/ClassNames";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { createTicket } from "api/tickets";
import Chart from "./Chart";
import useApp from "hooks/useApp";
import { BiExpand } from "react-icons/bi";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import TabsMenu from "components/shared/tabsMenu";
import { getFile } from "api/fileManager";
import { AiOutlineDownload } from "react-icons/ai";
import { pdfStyles } from "./utils/constants";
import PdfPreview from "components/PdfViewer/PdfViewer";
import { DocumentList } from "./DocumentList";
import { MdRemoveCircleOutline } from "react-icons/md";
import { getMappedAssetDocs, mapAssetDocs } from "api/docs";
import { getTrains } from "api/trains";
import { ListingProps } from "pages/all-assets/domain/types";
import { SelectedItem } from "types/common";
import Modal from "components/modal/Modal";
import useUnity from "hooks/UseUnity";

const Charts = ({ setModalOpen }: any) => {
  const {
    chartData,
    handleModalLoader,
    handleCloseModal,
    bubbleDetails,
    popupBubbleId,
    configureBubbleHandler,
    refreshUnityData,
    bubbleAllDetails,
  } = useContext(WebGLContext);

  const { setIsBubbleChart } = useUnity();
  const [dataCollection, setData] = useState<any>([]);
  const { pastDate: selectedPastDate } = useData();
  const [screen, setScreen] = useState("normal");
  const [selected, setSelected] = useState<any>(null);
  const [Editable, setEditable] = useState(false);
  const { userRole } = useApp();
  const [selectedItem, setSelectedItem] = useState(0);
  const [newTicket, setNewTicket] = useState<any>({
    comments: "",
    description: "",
    priority: null,
    severity: null,
    tagId: null,
    title: "",
  });

  const tabs = [
    {
      isSelected: selectedItem === 0,
      name: "Data",
      onClickHandler: () => setSelectedItem(0),
    },
    {
      isSelected: selectedItem === 1,
      name: "Documents",
      onClickHandler: () => setSelectedItem(1),
    },
  ];

  const actionbtns: any = [
    {
      name: "download",
      icon: <AiOutlineDownload size={17} />,
    },
    {
      name: "unmap",
      icon: <MdRemoveCircleOutline size={17} />,
    },
  ];

  const [Files, setFiles] = useState<any>(null);
  const [showFile, setshowFile] = useState<any>(false);
  const [FileId, setFileId] = useState<any>(null);
  const [TrainName, setTrainName] = useState<string | null>(null);
  const [UnitName, setUnitName] = useState<string | null>(null);
  const [EquipmentName, setEquipmentName] = useState<string | null>(null);
  const [TrainId, setTrainId] = useState<number | null>(null);

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setNewTicket({ ...newTicket, [e.target.id]: e.target.value });
  };

  const handleNewTicket = async (event: any) => {
    event.preventDefault();
    const response: any = await createTicket({
      comments: newTicket.comments,
      description: newTicket.description,
      priority: parseInt(newTicket.priority),
      severity: parseInt(newTicket.severity),
      tagId: selected.id,
      title: newTicket.title,
    });
    if (response.status === 200) {
      setScreen("normal");
    }
    setNewTicket({
      comments: "",
      description: "",
      priority: null,
      severity: null,
      tagId: null,
      title: "",
    });
  };

  useEffect(() => {
    if (
      userRole?.toLowerCase() == "admin" ||
      userRole?.toLowerCase() == "manager"
    ) {
      setEditable(true);
    }
  }, [userRole]);

  useEffect(() => {
    setIsBubbleChart(true);
    return () => {
      setIsBubbleChart(false);
    };
  }, []);


  useEffect(() => {
    const controller = new AbortController();
    let isApiSubscribed = true;
    if (isApiSubscribed) {
      handleModalLoader();
    }
    return () => {
      isApiSubscribed = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    }
  }, [chartData]);

  const [tag, setTag] = useState<SelectedItem>({
    action: "",
    value: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState<"trains" | "tags" | "expressions" | "units" | "equipments" | "docs">("trains");
  const [listing, setListing] = useState<ListingProps>(
    {
      selectedTrainId: null,
      selectedUnitId: null,
      selectedEquipmentId: null,
      selectedEquipment: null,
    }
  );

  const onAddDocs = () => {
    setSelectedPage('docs');
    setTag({ action: "new", value: listing });
  };

  useEffect(() => {
    (async () => {
      if (TrainName) {
        const response = await getTrains();
        if (response.status === 200 && response.data) {
          const selectedtrain = response.data.filter((train: any) => train.name === TrainName)[0].id;
          setTrainId(selectedtrain);
        }
      }
    })();
  }, [TrainName]);

  useEffect(() => {
    if (bubbleAllDetails && TrainId) {
      setListing({
        selectedTrainId: !(bubbleAllDetails.equipmentId || bubbleAllDetails.unitId) ? TrainId : null,
        selectedUnitId: !(bubbleAllDetails.equipmentId || TrainId) ? bubbleAllDetails.unitId : null,
        selectedEquipmentId: bubbleAllDetails.equipmentId || null,
        selectedEquipment: null,
      }
      );
    }
  }, [bubbleAllDetails, TrainId]);

  const configureBubble = () => {
    if (popupBubbleId) {
      configureBubbleHandler(popupBubbleId);
      setModalOpen(false);
    }
  };

  const containerClasses = classNames(
    "3xl:mt-16 mt-20",
    selectedItem === 0 ? "" : "max-w-[68vw] -ml-8",
    dataCollection.length > 1 && dataCollection.length <= 4
      ? selectedItem === 0
        ? "lg:max-w-[55vw] 2xl:max-w-[47vw]"
        : ""
      : "",
    dataCollection.length > 4 ? "sm:max-w-[70vw] ml-12" : "",
    dataCollection.length === 1 ? "sm:max-w-[24vw]" : "",
    dataCollection.length < 3 ? "h-[36vh]" : "min-h-[72vh]",
    "w-full rounded-md z-30 absolute flex opacity-95"
  );

  const chartContainerClasses = classNames(
    dataCollection.length > 1 && dataCollection.length <= 4
      ? "grid-cols-2"
      : "",
    dataCollection.length > 2 ? "grid-rows-2" : "",
    dataCollection.length > 4 ? "grid-cols-3" : "",
    "grid gap-3 h-full"
  );

  const getData = async (type: any) => {
    if (bubbleAllDetails) {
      let response = null;
      if (type === "unit") {
        response = await getMappedAssetDocs({
          unitId: bubbleAllDetails.unitId,
        });
      }
      if (type === "equipment") {
        response = await getMappedAssetDocs({
          equipmentId: bubbleAllDetails.equipmentId,
        });
        //response = await getAssetDocs();
      }
      if (response && response.data) {
        if (response.data.mappedAssetDocs && response.data.mappedAssetDocs.length > 0)
          setFiles(response.data.mappedAssetDocs);
        else
          setFiles(null);
        const hierarchy = response.data.hierarchy;
        if (type === "equipment") {
          setTrainName(hierarchy[0]);
          setUnitName(hierarchy[1]);
          setEquipmentName(hierarchy[2]);
        }
        else if (type === "unit") {
          setTrainName(hierarchy[0]);
          setUnitName(hierarchy[1]);
        }
        else if (type === "train") {
          setTrainName(hierarchy[0]);
        }
      }
    }
  };

  const docDownload = async (id: any, name: any) => {
    const response = await getFile(id);
    let result;
    if (response.data) {
      result = response.data;
    }
    const filename = name;
    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const OpenPDF = async (id: any) => {
    setFileId(id);
  };

  const unMapDoc = async (id: any) => {
    const response = await mapAssetDocs({
      fileId: id,
      isMap: false,
      trainId:
        !bubbleAllDetails.equipmentId &&
          !bubbleAllDetails.unitId &&
          bubbleAllDetails.trainId
          ? bubbleAllDetails.trainId
          : null,
      unitId:
        !bubbleAllDetails.equipmentId &&
          !bubbleAllDetails.trainId &&
          bubbleAllDetails.unitId
          ? bubbleAllDetails.unitId
          : null,
      equipmentId: bubbleAllDetails.equipmentId
        ? bubbleAllDetails.equipmentId
        : null,
    });
    if (response && response.status == 200) {
      getData(bubbleAllDetails.bubbletype);
    }
  };

  useEffect(() => {
    if (FileId) {
      setshowFile(true);
    }
  }, [FileId]);

  useEffect(() => {
    getData(bubbleAllDetails.bubbletype);
  }, [selectedItem, bubbleAllDetails]);


  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const handleClickOutside = (event: any) => {
    if (iframeRef.current && !iframeRef.current.contains(event.target)) {
      setshowFile(false);
      setFileId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const refreshData = async () => {
    setIsLoading(true);
    getData(bubbleAllDetails.bubbletype);
    setIsLoading(false);
  };


  return (
    <>
      {screen === "ticket" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div
            className={classNames(
              "h-full",
              "absolute z-20 inset-0 w-full bg-black/70"
            )}
          />

          <div
            className={classNames(
              "top-1/2",
              "w-full h-auto gradient p-0.5 rounded-md z-30 mt-7 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[850px]"
            )}
          >
            <div className="bg-dark pl-3 pt-3 pb-3 w-full rounded-md h-full max-h-[75vh] overflow-hidden overflow-y-scroll">
              <div className="flex flex-col py-3">
                <div className="flex justify-between">
                  <h1>Add trouble ticket</h1>
                  <div
                    className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                    onClick={() => setScreen("normal")}
                  >
                    <FiX size={25} />
                  </div>
                </div>
                <form className="mt-3" onSubmit={handleNewTicket}>
                  <div className="py-1">
                    <label htmlFor="title">Issue Title</label>
                    <input
                      className="input"
                      onChange={handleChange}
                      type="text"
                      id="title"
                      value={newTicket.title}
                    />
                  </div>

                  <div className="py-1">
                    <label htmlFor="description">Issue description</label>
                    <textarea
                      className="input"
                      onChange={handleChange}
                      id="description"
                      value={newTicket.description}
                    />
                  </div>

                  <div className="py-1">
                    <label htmlFor="comments">Resolution</label>
                    <textarea
                      className="input"
                      onChange={handleChange}
                      id="comments"
                      value={newTicket.comments}
                    />
                  </div>

                  <div className="py-2 flex justify-between gap-5">
                    <div className="flex flex-col gap-1 w-full">
                      <label>Severity</label>
                      <Select
                        value={newTicket.severity}
                        onValueChange={(value: any) =>
                          setNewTicket({ ...newTicket, severity: value })
                        }
                      >
                        <SelectTrigger className="w-full border-slate">
                          <SelectValue placeholder="severity" />
                        </SelectTrigger>
                        <SelectContent className="bg-dark border-slate">
                          <SelectItem value="1">1</SelectItem>
                          <SelectItem value="2">2</SelectItem>
                          <SelectItem value="3">3</SelectItem>
                          <SelectItem value="4">4</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label>Priority</label>
                      <Select
                        value={newTicket.priority}
                        onValueChange={(value: any) =>
                          setNewTicket({ ...newTicket, priority: value })
                        }
                      >
                        <SelectTrigger className="w-full border-slate">
                          <SelectValue placeholder="Priority" />
                        </SelectTrigger>
                        <SelectContent className="bg-dark border-slate">
                          <SelectItem value="3">Height</SelectItem>
                          <SelectItem value="2">Medium</SelectItem>
                          <SelectItem value="1">Low</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  {/* Ticket Dialog footer */}
                  <div className="flex justify-end gap-5 mt-2">
                    <button
                      className="cursor-pointer btn-sec"
                      onClick={() => setScreen("normal")}
                    >
                      Cancel
                    </button>
                    <button className="cursor-pointer btn" type="submit">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {!chartData && <div>Loading</div>}
      {screen === "normal" && chartData && dataCollection.length && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div
            className={classNames(
              "h-full",
              "absolute z-20 inset-0 w-full bg-black/70"
            )}
          />
          <div className="w-screen h-screen flex flex-wrap items-center justify-center z-[9999]">
            <div className={containerClasses}>
              <div
                className={classNames(
                  "bg-dark px-3 pt-3 pb-4 rounded-md w-full flex flex-wrap"
                )}
              >
                {/* Header */}
                <div className="flex justify-between w-full h-min -mb-4 3xl:mb-0">
                  <div className="flex gap-3 items-center">
                    <h1 className="text-2xl font-bold">
                      {bubbleDetails?.name}
                    </h1>

                    {popupBubbleId && Editable && (
                      <div
                        className="cursor-pointer text-green"
                        onClick={configureBubble}
                      >
                        <FiSettings size={20} />
                      </div>
                    )}
                  </div>
                  <div
                    className="bg-green py-1 px-1.5 rounded-md cursor-pointer grid place-content-center"
                    onClick={handleCloseModal}
                  >
                    <FiX size={20} />
                  </div>
                </div>
                <div className="w-full h-0 mt-4">
                  <TabsMenu tabs={tabs} />
                </div>
                <div className="h-[82%] w-full">
                  <div className={classNames("flex w-full justify-between pb-2", selectedItem === 1 ? "lg:-mt-0 2xl:-mt-2 lg:-mb-7 2xl:-mb-5" : "lg:-mt-1 2xl:-mt-2")}>
                    <div className="flex font-bold text-green lg:text-md 2xl:text-xl">
                      {TrainName && <div className="pr-1">{TrainName}{" →"}</div>}
                      {UnitName && <div className="pr-1">{UnitName}{" → "}</div>}
                      {EquipmentName && <div>{EquipmentName}</div>}
                    </div>
                    {selectedItem === 1 && <div className="mr-5 btn w-[7%] flex justify-center" onClick={onAddDocs}>Add</div>}
                  </div>
                  {selectedItem === 0 && dataCollection && (
                    <div className={chartContainerClasses}>
                      {dataCollection.map((data: any, index: any) => (
                        <div key={index}>
                          <Chart
                            data={data}
                            key={index}
                            screen={screen}
                            setScreen={setScreen}
                            setSelected={setSelected}
                            refreshUnityData={refreshUnityData}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedItem === 1 &&
                    DocumentList(
                      Files,
                      OpenPDF,
                      actionbtns,
                      docDownload,
                      unMapDoc
                    )}
                  {selectedItem === 1 && showFile && FileId && (
                    <div ref={iframeRef}>
                      <PdfPreview id={FileId} style={pdfStyles} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal page={selectedPage} isAlarm={undefined} selectedTag={tag} setSelectedTag={setTag} refresh={refreshData} isLoading={isLoading} listing={listing} />
      {screen === "big" && (
        <div className="absolute z-20 left-[0%] top-[0%] inset-0 w-full bg-black/70 flex justify-center items-center">
          <div className="fixed bg-dark rounded-xl">
            <>
              <BorderGradient>
                <div className="bg-dark rounded-md">
                  <div className="flex justify-end p-2">
                    <button
                      className="cursor-pointer bg-green rounded-md p-1"
                      onClick={() => setScreen("normal")}
                    >
                      <BiExpand size={18} />
                    </button>
                  </div>
                  <div className="min-w-[60vw] px-4">
                    <BorderGradient>
                      <div className="bg-dark px-1 py-2 rounded-md h-[60vh]">
                        <Area
                          item={selected}
                          type="tag"
                          areaType="yearly"
                          renderType="lazyLoading"
                          maintainAspectRatio={false}
                        />
                      </div>
                    </BorderGradient>
                  </div>
                  <div className="p-4">
                    <div className="flex items-center">
                      <p>Tag Name:</p>
                      <div className="flex-1" />
                      <p>{selected.name}</p>
                    </div>
                    <div className="flex items-center">
                      <p>Description:</p>
                      <div className="flex-1" />
                      <p>{selected.desc}</p>
                    </div>
                    <div className="flex items-center">
                      <p>Engineering Unit:</p>
                      <div className="flex-1" />
                      <p>{selected.uom}</p>
                    </div>
                  </div>
                </div>
              </BorderGradient>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default Charts;
